import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/dataPermission/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/common-service/dataPermission/add',
        method: 'POST',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/common-service/dataPermission/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/common-service/dataPermission/del',
        method: 'POST',
        data
    })
}